export const vehicles = [
    {
      name: 'Baleno',
      images: [
        '/images/cars/Baleno/IMG_1242.jpg',
        '/images/cars/Baleno/IMG_1247.thumbnail.jpg',
        '/images/cars/Baleno/IMG_1248.jpg',
        '/images/cars/Baleno/IMG_1257.jpg',
      ],
      price: 'Price/day',
    },
    {
      name: 'Creta',
      images: [
        '/images/cars/Creta/IMG_5792.jpg',
        '/images/cars/Creta/IMG_5786.jpg',
        '/images/cars/Creta/IMG_5794.jpg',
      ],
      price: 'Price/day',
    },
    {
      name: 'Glanza',
      images: [
        '/images/cars/Glanza/IMG_7394.thumbnail.jpg',
        '/images/cars/Glanza/IMG_7392.thumbnail.jpg',
        '/images/cars/Glanza/IMG_7389.thumbnail.jpg',
        '/images/cars/Glanza/IMG_7399.thumbnail.jpg',
      ],
      price: '$Price/day',
    },
    {
      name: 'i20',
      images: [
        '/images/cars/i20/IMG_3292.thumbnail.jpg',
        '/images/cars/i20/IMG_3290.thumbnail.jpg',
        '/images/cars/i20/IMG_3288.thumbnail.jpg',
        '/images/cars/i20/IMG_3281.thumbnail.jpg',
      ],
      price: 'Price/day',
    },
  ];
  
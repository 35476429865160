function Footer() {
  return (
    <footer className="bg-gray-900 text-center py-6">
      <div className="container mx-auto px-4">
        <p className="text-gray-400 text-sm md:text-base">
          &copy; 2024 Cruiseon Cars. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect } from 'react';

function BookNowForm({ onClose, carOptions = [], selectedCar }) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    car: selectedCar || (carOptions.length > 0 ? carOptions[0].name : ''),
    startDate: '',
    endDate: '',
  });

  const [minDate, setMinDate] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set minimum date to today
    const today = new Date().toISOString().split('T')[0];
    setMinDate(today);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!formData.name || !formData.phone || !formData.email || !formData.car || !formData.startDate || !formData.endDate) {
      console.log('All fields are required');
      return;
    }

    setLoading(true);

    // Formspree submission
    const formspreeEndpoint = 'https://formspree.io/f/xwpkekka'; // Replace with your Formspree endpoint
    const formDataToSend = new FormData();

    formDataToSend.append('name', formData.name);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('car', formData.car);
    formDataToSend.append('startDate', formData.startDate);
    formDataToSend.append('endDate', formData.endDate);

    fetch(formspreeEndpoint, {
      method: 'POST',
      body: formDataToSend,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          setConfirmationMessage('Mail sent!');
        } else {
          setConfirmationMessage('Failed to send mail. Try again!');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        setConfirmationMessage('Not able to send mail!');
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-4">Book a Car</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            className="mb-4 p-2 w-full"
            required
          />
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
            className="mb-4 p-2 w-full"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="mb-4 p-2 w-full"
            required
          />

          {/* Dropdown for car selection */}
          <select
            name="car"
            value={formData.car}
            onChange={handleChange}
            className="mb-4 p-2 w-full"
            required
          >
            {carOptions.length > 0 ? (
              carOptions.map((car) => (
                <option key={car.name} value={car.name}>
                  {car.name}
                </option>
              ))
            ) : (
              <option value="" disabled>No cars available</option>
            )}
          </select>

          {/* Labels and Date Inputs */}
          <label className="block mb-1 text-sm font-medium">Start Date</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            min={minDate} // Set the min attribute dynamically
            className="mb-4 p-2 w-full"
            required
          />

          <label className="block mb-1 text-sm font-medium">End Date</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            min={formData.startDate || minDate} // Ensure the end date is after or on the start date
            className="mb-4 p-2 w-full"
            required
          />

          <button type="submit" className="bg-orange-500 text-white p-2 rounded w-full">
            Submit
          </button>
        </form>

        {loading && <div className="mt-4 text-center">Submitting...</div>}

        {confirmationMessage && (
          <p className={`mt-4 ${confirmationMessage.includes('sent') ? 'text-green-600' : 'text-red-600'}`}>
            {confirmationMessage}
          </p>
        )}

        <button onClick={onClose} className="text-gray-500 mt-4">
          Close
        </button>
      </div>
    </div>
  );
}

export default BookNowForm;

